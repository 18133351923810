body {
    &.is_home {
        //background-color: #0b2b4a;
    }
}

.ii-banner {
    background-color: $brand-primary;
    background-image: url("/images/destello.webp");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center 35px;
    position: relative;
    min-height: 585px;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(md) {
        background-image: url("/images/destello-desktop.webp");
        background-position: center -20px;
    }
    @include media-breakpoint-up(lg) {
        background-position: center 35px;
        background-size: 65% auto;
    }
    @include media-breakpoint-up(xl) {
        min-height: 670px;
    }
    @media (min-width: 1400px) {
        min-height: 685px;
    }
    @media (min-width: 1900px) {
        background-position: center 0;
        background-size: 50% auto;
        min-height: 710px;
    }

    .ellipse-banner {
        background-image: url("/images/banner/ellipse-banner.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        bottom: 13%;
        transform: translate(-50%, 65%);
        height: 300px;
        width: 100%;
        left: 50%;
        right: 0;
        margin: 0 auto;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            background-size: 100% auto;
            background-position: bottom center;
            bottom: 5%;
            height: 150px;
        }
        @include media-breakpoint-up(xl) {
            bottom: 5%;
            height: 190px;
        }
        @media (min-width: 1400px) {
            height: 220px;
        }
        @media (min-width: 1900px) {
            bottom: 4%;
            height: 260px;
        }
    }

    .container {
        position: absolute;
        top: 64%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        transition: all 0.5s ease-out;

        .ellipse {
            background-image: url("/images/ellipse.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            top: 56%;
            transform: translate(-50%, -50%);
            height: 280px;
            width: 100%;
            left: 50%;
            right: 0;
            margin: 0 auto;
            z-index: -1;

            @include media-breakpoint-up(md) {
                top: 44%;
                height: 355px;
            }
        }

        .homeHeading {
            font-family: $fontTitle;
            color: white;
            text-align: center;
            transition: all 0.5s ease-out;

            img {
                width: 100%;
                margin: 0 auto 50px;

                @include media-breakpoint-up(lg) {
                    max-width: 720px;
                }
                @include media-breakpoint-up(xl) {
                    margin-top: 0;
                    max-width: 925px;
                }
            }


            h1 {
                font-size: 18px;
                font-family: 'Gotham-Book';
                width: 310px;
                margin: 0 auto;

                strong {
                    font-family: 'Gotham-Bold';
                }

                @include media-breakpoint-up(sm) {
                    max-width: 685px;
                    width: 100%;
                }
                @include media-breakpoint-up(lg) {
                    font-size: 20px;
                }

                label {
                    color: $brand-primary;
                    margin: 0;
                }
            }
        }
    }

}

//.ii-banner

.ii-publicity {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(189, 204, 213, 1) 100%);
    padding-top: 123px;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(lg) {
        padding-top: 180px;
    }
    @media (min-width: 1900px) {
        padding-top: 260px;
    }

    img {
        width: 200px;

        @include media-breakpoint-up(md) {
            width: 400px;
        }
    }

    .title {
        margin-top: 62px;
        margin-bottom: 12px;

        @include media-breakpoint-up(lg) {
            font-size: 30px;
        }

        span {
            color: $brand-alternative;
        }
    }

    p {
        color: $brand-text;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin-bottom: 20px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(xl) {
            font-size: 19px;
            margin: 0 auto;
            width: 100%;
        }

        &.blue {
            color: $brand-third;

            @include media-breakpoint-up(xl) {
                margin: 20px auto 60px;
                width: 565px;
            }
        }
    }

    .ellipse {
        background-image: url("/images/ellipse-gris.svg");
        background-repeat: no-repeat;
        background-size: 140% auto;
        background-position: center 0;
        position: relative;
        width: 100%;
        height: 45px;
        margin-top: 105px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            height: 78px;
        }
    }
}

//.ii-publicity

.ii-inflate-ideas {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(215, 224, 230, 1) 40%);
    position: relative;
    transition: all 0.5s ease-out;

    .glob {
        background-image: url("/images/globo.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: absolute;
        right: 0;
        top: -110px;
        width: 105px;
        height: 143px;
        animation: moveU 20s ease-in-out infinite;
        //transform: rotate(-23.589deg);
        //transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            height: 230px;
            width: 174px;
            top: -210px;
        }
        @media (min-width: 1800px) {
            height: 350px;
            width: 220px;
        }
    }

    .bottle {
        background-image: url("/images/botella.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 100% 0;
        position: absolute;
        left: 0;
        top: 435px;
        height: 170px;
        width: 65px;
        //transition: all 0.5s ease-out;
        animation: flip 7s ease-in-out infinite;

        @include media-breakpoint-up(xl) {
            left: 0;
            top: 44%;
            height: 300px;
            width: 107px;
        }
        @media (min-width: 1800px) {
            height: 350px;
            width: 220px;
        }
    }

    .wrap-pulpo-champions {
        align-items: baseline;
        justify-content: space-around;
        margin-bottom: 50px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            align-items: flex-start;
            justify-content: space-between;
        }

        img {
            max-width: 200px;
            width: 35%;

            &.pulpo {
                animation: inflate 8s ease-in-out infinite;
                @include media-breakpoint-up(lg) {
                    margin-top: -30px;
                }
            }

            &.champions {
                animation: inflate 16s ease-in-out infinite;
            }

            &.title {
                @include media-breakpoint-up(lg) {
                    max-width: 300px;
                    width: 50%;
                    margin-top: 50px;
                }
            }
        }
    }

    .wrap-teleton {
        align-items: baseline;
        justify-content: center;
        margin-bottom: 50px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            height: 200px;
        }

        img {
            max-width: 200px;
            width: 45%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                max-width: 275px;
                top: -160px;
                position: relative;
            }
        }
    }

    .wrap-explore {
        margin-top: 80px;
        transition: all 0.5s ease-out;

        .title {
            width: 250px;
        }

        p {
            color: $brand-text;
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
            margin-top: 20px;

            @include media-breakpoint-up(xl) {
                font-size: 18px;
                line-height: 20px;
            }
        }

        .subTitle {
            color: $brand-alternative;
            width: 250px;

            @include media-breakpoint-up(xl) {
                font-size: 26px;
                line-height: 30px;
                width: 320px;
            }
        }

        .img-circle {
            background-color: rgba(0, 167, 225, 0.20);
            border-radius: 50%;
            height: 285px;
            margin: 75px auto 0;
            width: 285px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                height: 350px;
                width: 350px;
            }

            @media (min-width: 1400px) {
                height: 500px;
                position: relative;
                top: -45px;
                margin-top: 0;
                width: 500px;
            }

            img {
                width: 210px;
                transition: all 0.5s ease-out;

                &.animate {
                    animation:
                            bounce alternate infinite
                            cubic-bezier(.2, .65, .6, 1);
                    --bounce-offset: -20px;
                    animation-duration: 200ms;
                }

                @include media-breakpoint-up(lg) {
                    width: 250px;
                    top: 20px;
                    position: relative;
                }
                @media (min-width: 1400px) {
                    width: 350px;
                }
            }
        }

    }
}

//.ii-inflate-ideas

.ii-inflate-publicity {
    background: linear-gradient(0deg, rgba(0, 167, 225, 0.60) 0, rgba(189, 204, 213, 0.00) 66%, rgba(189, 204, 213, 0.00) 100%);
    position: relative;
    margin-top: 120px;
    padding-bottom: 60px;
    transition: all 0.5s ease-out;

    @media (min-width: 1600px) {
        margin-top: 200px;
    }

    .arc {
        background-image: url("/images/arco.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: absolute;
        right: 21px;
        top: -96px;
        width: 92px;
        height: 57px;
        animation: inflate 12s ease-in-out infinite;
        //transform: rotate(-23.589deg);

        @include media-breakpoint-up(lg) {
            height: 100px;
            width: 165px;
        }
        @media (min-width: 1800px) {
            height: 350px;
            top: -320px;
            width: 330px;
        }
    }

    .subTitle {
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .list {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-around;
        }

        ul {
            margin-left: 18px;

            li {
                color: $brand-third;

                &::marker {
                    color: $brand-alternative;
                }
            }
        }
    }

    .col1 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 40%;
            max-width: 40%;
            width: 40%;
        }
    }
    .col2 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 60%;
            max-width: 60%;
            width: 60%;
        }
    }

    .img-circle {
        background-color: #BADD8F;
        border-radius: 50%;
        height: 285px;
        margin: 75px auto 0;
        padding-top: 15px;
        width: 285px;

        @include media-breakpoint-up(lg) {
            height: 350px;
            width: 350px;
        }

        @media (min-width: 1400px) {
            height: 500px;
            position: relative;
            top: -45px;
            margin-top: 0;
            width: 500px;
        }

        img {
            width: 128px;

            @include media-breakpoint-up(lg) {
                width: 160px;
            }
            @media (min-width: 1400px) {
                position: relative;
                top: 10px;
                width: 225px;
            }
        }
    }

    .BtnStd {
        margin-top: 60px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 56px;
        }
    }

}

//.ii-inflate-publicity

.ii-inflate-structure {
    background: rgba(0, 167, 225, 0.60);
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;
    transition: all 0.5s ease-out;

    .kid {
        background-image: url("/images/kid.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 100% 0;
        position: absolute;
        left: 0;
        top: -120px;
        width: 92px;
        height: 215px;
        //transform: rotate(-23.589deg);
        animation: squish 6s ease-in infinite;

        @include media-breakpoint-up(lg) {
            background-position: 85% 0;
            height: 320px;
            width: 150px;
        }
        @media (min-width: 1800px) {
            height: 470px;
            top: -220px;
            width: 330px;
        }
    }

    .subTitle {
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .list {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-around;
        }

        ul {
            margin-left: 18px;

            li {
                color: #002856;

                &::marker {
                    color: $brand-alternative;
                }
            }
        }
    }

    .col1 {
        @media (min-width: 1400px) {
            margin-left: 10%;
            flex: 0 0 40%;
            max-width: 40%;
            width: 40%;
        }
    }
    .col2 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 50%;
            max-width: 50%;
            width: 50%;
        }
    }

    .img-circle {
        height: 285px;
        margin: 95px auto 0;
        padding-top: 15px;
        position: relative;
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: 470px;
        }
        @media (min-width: 1400px) {
            top: 50px;
            max-width: 550px;
        }

        &:after {
            content: '';
            background-color: #76BC21;
            border-radius: 50%;
            height: 95%;
            position: absolute;
            top: -37px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 80%;

            @include media-breakpoint-up(md) {
                height: 310px;
                width: 310px;
            }
            @media (min-width: 1400px) {
                height: 500px;
                top: -105px;
                width: 500px;
            }
        }

        img {
            position: relative;
            width: 100%;
            z-index: 1;
        }
    }

    .BtnStd {
        margin-top: 20px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 56px;
        }
    }

}

//.ii-inflate-structure

.ii-inflate-motley {
    background: rgba(0, 167, 225, 0.60);
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;
    transition: all 0.5s ease-out;

    @media (min-width: 1400px) {
        padding-top: 190px;
        padding-bottom: 150px;
    }

    .pillar {
        background-image: url("/images/pillar.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 0 0;
        position: absolute;
        right: 0;
        top: 53%;
        width: 78px;

        @include media-breakpoint-up(lg) {
            background-position: 0 0;
            height: 320px;
            top: -100px;
            width: 150px;
        }
        @media (min-width: 1800px) {
            height: 375px;
            top: -160px;
            width: 250px;
        }
    }

    .subTitle {
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .list {
        margin-top: 40px;

        ul {
            margin-left: 18px;

            li {
                color: #002856;

                &::marker {
                    color: $brand-alternative;
                }
            }
        }
    }

    .col1 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 40%;
            max-width: 40%;
            width: 40%;
        }
    }
    .col2 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 60%;
            max-width: 60%;
            width: 60%;
        }
    }

    .img-circle {
        background-color: #E9EAAD;
        border-radius: 50%;
        height: 285px;
        margin: 75px auto 0;
        width: 285px;

        @include media-breakpoint-up(lg) {
            height: 350px;
            width: 350px;
        }

        @media (min-width: 1400px) {
            height: 500px;
            position: relative;
            top: -45px;
            margin-top: 0;
            width: 500px;
        }

        img {
            top: -23px;
            position: relative;
            width: 145px;

            @include media-breakpoint-up(lg) {
                width: 185px;
                top: -40px;
            }
            @media (min-width: 1400px) {
                width: 250px;
            }
        }
    }

    .BtnStd {
        margin-top: 50px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 56px;
        }
    }

}

//.ii-inflate-motley

.ii-inflate-interactive {
    background: rgba(0, 167, 225, 0.60);
    position: relative;
    padding-top: 80px;
    padding-bottom: 60px;
    transition: all 0.5s ease-out;

    .subTitle {
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .list {
        margin-top: 40px;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-around;
        }

        ul {
            margin-left: 18px;

            li {
                color: #002856;

                &::marker {
                    color: $brand-alternative;
                }
            }
        }
    }

    .col1 {
        @media (min-width: 1400px) {
            margin-left: 10%;
            flex: 0 0 40%;
            max-width: 40%;
            width: 40%;
        }
    }
    .col2 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 50%;
            max-width: 50%;
            width: 50%;
        }
    }

    .img-circle {
        background-color: #B8D9F6;
        border-radius: 50%;
        height: 285px;
        margin: 75px auto 0;
        width: 285px;

        @include media-breakpoint-up(lg) {
            height: 350px;
            width: 350px;
        }
        @media (min-width: 1400px) {
            height: 500px;
            position: relative;
            top: -45px;
            margin-top: 0;
            width: 500px;
        }

        img {
            position: relative;
            left: -10px;
            top: 10px;
            max-width: 315px;
            width: 315px;

            @include media-breakpoint-up(lg) {
                width: 395px;
                max-width: initial;
            }
            @media (min-width: 1400px) {
                top: 40px;
                width: 530px;
            }
        }
    }

    .BtnStd {
        margin-top: 50px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 56px;
        }
    }

}

//.ii-inflate-interactive

.ii-inflate-more-products {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0, rgba(0, 167, 225, 0.60) 100%);
    position: relative;
    padding-top: 80px;
    transition: all 0.5s ease-out;

    .tire {
        background-image: url("/images/tire.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: 100% 0;
        position: absolute;
        left: 0;
        top: -100px;
        width: 78px;
        height: 155px;
        animation: jelly 4s ease-in-out infinite;
        //transform: rotate(-23.589deg);

        @include media-breakpoint-up(lg) {
            background-position: 80% 0;
            height: 275px;
            top: -350px;
            width: 135px;
        }
        @media (min-width: 1800px) {
            height: 380px;
            top: -600px;
            width: 250px;
        }
    }

    .subTitle {
        margin-bottom: 15px;

        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .list {
        margin-top: 40px;

        ul {
            margin-left: 18px;

            li {
                color: #002856;

                &::marker {
                    color: $brand-alternative;
                }
            }
        }
    }

    .col1 {
        @media (min-width: 1400px) {
            margin-left: 10%;
            flex: 0 0 40%;
            max-width: 40%;
            width: 40%;
        }
    }
    .col2 {
        @media (min-width: 1400px) {
            margin-left: 0;
            flex: 0 0 50%;
            max-width: 50%;
            width: 50%;
        }
    }

    .img-circle {
        background-color: #F9E1F2;
        border-radius: 50%;
        height: 285px;
        margin: 75px auto 0;
        width: 285px;

        @include media-breakpoint-up(lg) {
            height: 350px;
            width: 350px;
        }
        @media (min-width: 1400px) {
            height: 500px;
            position: relative;
            top: -45px;
            margin-top: 0;
            width: 500px;
        }

        img {
            position: relative;
            top: 34px;
            max-width: 220px;
            width: 220px;

            @include media-breakpoint-up(lg) {
                max-width: initial;
                width: 300px;
            }
            @media (min-width: 1400px) {
                left: 25px;
                top: 35px;
                width: 400px;
            }
        }
    }

    .BtnStd {
        margin-top: 50px;

        @include media-breakpoint-up(lg) {
            font-size: 18px;
            line-height: 56px;
        }
    }

    .ellipse {
        background-image: url("/images/ellipse-azul.svg");
        background-repeat: no-repeat;
        background-size: 140% auto;
        background-position: center 0;
        position: relative;
        width: 100%;
        height: 45px;
        margin-top: 105px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(xl) {
            height: 55px;
        }

        @media (min-width: 1400px) {
            height: 90px;
        }
        @media (min-width: 1800px) {
            height: 100px;
        }
        @media (min-width: 1900px) {
            height: 105px;
        }
    }
}

//.ii-inflate-more-products

.ii-inflate-maintenance {
    //background: $brand-primary;
    position: relative;
    padding-bottom: 60px;
    transition: all 0.5s ease-out;

    .glob {
        background-image: url("/images/globo.svg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        position: absolute;
        right: 0;
        top: -100px;
        width: 78px;
        height: 155px;
        animation: moveU 20s ease-in-out infinite;
        //transform: rotate(-23.589deg);

        @include media-breakpoint-up(lg) {
            height: 230px;
            width: 125px;
            top: -280px;
        }
        @media (min-width: 1800px) {
            height: 350px;
            width: 220px;
        }
    }

    .wrap-info {
        position: relative;
        padding-top: 25px;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
        }

        &:before {
            background: $brand-primary;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 625px;
            width: 100%;
            z-index: -1;

            @include media-breakpoint-up(lg) {
                height: 515px;
            }
        }
    }

    .subTitle {
        color: $brand-third;
        margin-bottom: 15px;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        width: 200px;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        @include media-breakpoint-up(xl) {
            font-size: 26px;
            line-height: 30px;
        }
    }

    p {
        color: white;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            width: 640px;
        }
        @include media-breakpoint-up(xl) {
            font-size: 18px;
            line-height: 20px;
        }
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        margin-top: 27px;

        @include media-breakpoint-up(md) {
            margin: 30px auto 40px auto;
            width: 530px;
        }

        @include media-breakpoint-up(lg) {
            margin: 30px auto 25px auto;
        }


        a {

            &.active {
                h3 {
                    color: $brand-alternative;
                }
            }
            &:hover {
                @include media-breakpoint-up(md) {
                    h3 {
                        color: $brand-alternative;
                    }
                }
            }

            h3 {
                color: $brand-third;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 14px;
                text-transform: uppercase;

                @include media-breakpoint-up(xl) {
                    font-size: 16px;
                    line-height: 18px;
                }
            }

        }
    }

    .BtnStd {
        margin-top: 70px;
    }

    .slick-maintenance {

        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            width: 680px;
        }

        .item {
            img {
                margin: 0 auto;

                @include media-breakpoint-up(md) {
                    max-width: 530px;
                }
            }
        }
    }
    .ellipse {
        background-image: url("/images/ellipse-azul.svg");
        background-repeat: no-repeat;
        background-size: 140% auto;
        background-position: center 0;
        position: absolute;
        width: 100%;
        height: 45px;
        bottom: 25px;
        left: 0;
        rotate: 180deg;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 80px;
            bottom: 25px;
        }
        @include media-breakpoint-up(lg) {
            height: 120px;
            bottom: 15px;
            z-index: -1;
        }
    }

    .arrows-slider {
        position: absolute;
        margin: 0 auto;
        top: 42%;
        width: 100%;
        transform: translate(-50%, 50%);
        left: 50%;

        li {
            filter: drop-shadow(5px 3px 4px #000);;
            list-style: none;
            position: relative;

            &.prev {
                background-position: 0 0;
                background-image: url("/images/prev-arrow.svg");
                background-size: 100% auto;
                left: 0;
                right: auto;
                width: 34px;
                height: 65px;
                position: absolute;
            }
            &.next {
                background-position: 0 0;
                background-image: url("/images/next-arrow.svg");
                background-size: 100% auto;
                right: 0;
                left: auto;
                width: 34px;
                height: 65px;
                position: absolute;
            }
        }
    }

}

//.ii-inflate-maintenance

.ii-inflate-clients {
    position: relative;
    padding-top: 60px;
    transition: all 0.5s ease-out;

    .subTitle {
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 48px;
            width: 640px;
        }

        span {
            color: $brand-third;
        }
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        .item {
            text-align: center;
            width: 33.33%;

            img {
                margin: 0 auto;
                width: 60%;

                &.animate {
                    animation: squish 2s ease-in infinite;
                }
            }

            &.small {
                img {
                    width: 40%;
                }
            }
        }
    }

    .ellipse {
        background-image: url("/images/ellipse-maintenace-green.svg");
        background-repeat: no-repeat;
        background-size: 140% auto;
        background-position: center 0;
        position: relative;
        width: 100%;
        height: 45px;
        opacity: 0.4;
        margin-top: 90px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 115px;
        }
        @include media-breakpoint-up(xl) {
            height: 160px;
        }
        @media (min-width: 1400px) {
            height: 200px;
        }
        @media (min-width: 1900px) {
            height: 250px;
        }
    }
}

//.ii-inflate-clients

.ii-inflate-contact {
    background-color: rgba(118, 188, 33, 0.4);
    position: relative;
    padding-top: 15px;
    transition: all 0.5s ease-out;

    .subTitle {
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        text-transform: uppercase;
        margin-bottom: 10px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 48px;
            text-align: left;
        }

        span {
            color: $brand-third;
            display: block;
        }
    }

    p {
        color: $brand-primary;
        display: block;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            font-size: 19px;
            text-align: left;
        }
    }

    form {
        margin-top: 60px;
        transition: all 0.5s ease-out;

        .form-group {
            label {
                color: $brand-primary;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                display: block;
            }

            input {
                background-color: transparent;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 1px solid $brand-primary;
                border-radius: 0;
                box-shadow: none;
                color: $brand-alternative;
                margin-bottom: 52px;
                width: 100%;
            }

            button {
                margin-top: 65px;

                @include media-breakpoint-up(lg) {
                    margin-top: 0;
                    margin-left: 0;
                }
            }
        }
    }

    .img-circle {
        background-color: $brand-alternative;;
        border-radius: 50%;
        height: 320px;
        margin: 40px auto 0;
        top: 25px;
        position: relative;
        width: 320px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            height: 367px;
            width: 367px;
        }

        @include media-breakpoint-up(xl) {
            height: 460px;
            width: 460px;
        }

        @media (min-width: 1900px) {
            height: 600px;
            left: -175px;
            width: 670px;
        }

        .slick-contact {

            .item {
                height: 300px;

                @include media-breakpoint-up(md) {
                    height: 500px;
                }
                img {
                    position: relative;
                    top: 20px;
                    left: 5px;
                    width: 210px !important;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(lg) {
                        width: 300px !important;
                    }
                    @include media-breakpoint-up(xl) {
                        width: 320px !important;
                    }
                    @media (min-width: 1900px) {
                        width: 415px !important;
                    }
                }
            }

        }

    }

}

//.ii-inflate-contact