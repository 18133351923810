// fonts
/** Sizes */
$baseFontSize: 16px;
$bp-1k: 120em;
$bp-2k: 160em;

/** Media Query */
$mq-2k: "(min-width: #{$bp-2k})";
$mq-retina: "(-webkit-min-device-pixel-ratio: 2),(min-resolution: 192dpi)";

$fontBase: "Rubik, sans-serif";
$fontTitle: "Rubik, sans-serif";

/** Transition time */
$transitionTime: 0.5s;
$radius: 60px;

// Colors Brand

$brand-primary: #0D2D52;
$brand-secondary: #62737C;
$brand-third: #00A7E1;
$brand-alternative: #76BC21;
$back-alternative: #BDCCD5;
$back-gray: #D7E0E6;
$brand-light: white;
$brand-dark: #002856;
$brand-text: #62737C;
$brand-error: red;


//Shadow
$shadow-1: rgba(0, 0, 0, 0.09);

html,
body {
    font-family: 'Rubik', sans-serif;;
    font-size: em($baseFontSize);
    color: #343434;

    @media #{$mq-2k} {
        font-size: em(16px)*1.3;
    }

    &.noScroll{
        .iiNav{
            opacity: 1;
            visibility: visible;
            transition: opacity 250ms ease, visibility 250ms ease;

        }
        header {

            .ii-hdrMb .hamburger{
                span{
                    background: $brand-primary;
                    width: 30px;

                    &:first-child{
                        transform: translate(0px, 10px) rotate(45deg);
                    }
                    &:nth-child(2){
                        opacity: 0;
                    }
                    &:last-child{
                        transform: rotate(-45deg) translate(7px, -7px);
                    }
                }
            }
            ul{
                color:$brand-primary;
            }
            .ii-cta{
                padding-top: 50px;
                >span{
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
        .btnLined{
            border-color: $brand-primary;
            color:$brand-primary;
            text-align: center;
        }
    }
}

main {
    position: relative;
    overflow: hidden;
}
