.bg-ii-blue-500 {
    background-color: #053456;
}

.bg-hover-ii-blue-500 {
    &:hover {
        background-color: #053456;
    }
}

.bg-ii-yellow-500 {
    background-color: #fec42e;
}

.bg-hover-ii-yellow-500 {
    &:hover {
        background-color: #fec42e;
    }
}

footer {
    background-color: $brand-dark;
    padding-bottom: 84px;
    padding-top: 84px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: 50px;
        padding-top: 50px;
    }

    .wrap-elements {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            margin: 0 auto;
            width: 950px;
        }

        img {
            width: 200px;
        }

        .know-more {
            margin: 60px auto 70px;
            text-align: center;
            width: 300px;

            @include media-breakpoint-up(lg) {
                margin: 55px auto 55px;
            }

            label {
                color: white;
                display: inline-block;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                margin: 0;

                @include media-breakpoint-up(lg) {
                    display: block;
                    text-align: left;
                }

                span {
                    color: $brand-alternative;
                }
            }
            a {
                color: $brand-alternative;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                @include media-breakpoint-up(lg) {
                    text-align: left;
                }
            }
        }
    }

    .social-media {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 250px;

        a {
            background-position: center center;
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            height: 28px;
            width: 38px;

            &.facebook {
                background-image: url("/images/icon-facebook.svg");
            }
            &.instagram {
                background-image: url("/images/icon-instagram.svg");
            }
            &.youtube {
                background-image: url("/images/icon-youtube.svg");
            }
            &.maps {
                background-image: url("/images/icon-maps.svg");
                color: $brand-alternative;
                background-size: 38px auto;
                height: 70px;
                line-height: 135px;
                font-size: 10px;
                text-align: center;
                width: 68px;
            }
        }
    }
}
